import React, { useState, useEffect } from 'react'
import Footer from '../components/commonComponent/Footer'
import Header from '../components/commonComponent/Header'
import SideBar from '../components/commonComponent/SideBar'
import TradeModel from '../components/Trade/Model/TradeModel'
import TradeTable from '../components/Trade/TradeTable'
// import TradeTable from '../components/TradeOld/TradeTable'
import { stockNews } from '../services/TradeService'
import { Link, useNavigate } from 'react-router-dom'
import OpenTrade from '../components/Home/Models/OpenTrade'
import { getPortfoloio } from '../services/PortfolioService'
import TradeCalculater from '../components/Home/Models/TradeCalculater'
import { getProviders } from '../services/ProviderServices'
const Trade = () => {
    const [tradeCode,setTradeCode]=useState('')
    const [portfololioList,setPortfolioList]=useState([])
    const [openTradeRun,setOpenTradeRun]=useState(false)
    const [calculatedData,setCalculatedData]=useState()
    const [runPortFolioAPI,setRunPortFolioAPI]=useState(true)
    const [calculatedTradeData,setCalculatedTradeData]=useState({})
    const [runAgainDashBoard, setRunAgainDashBoard] = useState([])
    const [userType,setUserType]=useState('')
    const [providerList, setProviderList] = useState([])
    useEffect(()=>{
        // let data={
        //     userId:'1',
        //     role:'user'
        // }
        // localStorage.setItem('userDetails',JSON.stringify(data))
      
        let nm=localStorage.getItem('userDetails')
        setUserType(JSON.parse(nm))
    },[])
    useEffect(() => {
        if(userType?.userId>0){
            try {
                getPortfoloio(userType?.userId).then((res) => {
                    if (res?.status === 200) {
                        setPortfolioList(res.data)
                        setRunPortFolioAPI(false)
                    }
                })
            } catch (error) {
                
            }
        }
        
    }, [runPortFolioAPI === true,userType?.userId])
    useEffect(() => {
        let data = {
            PageSize: 1000,
            PageNumber: 1
        }
        try {
            getProviders(data).then((res) => {
                if (res?.status === 200) {
                    setProviderList(res.data.data)
                }
            })
        } catch (error) {

        }

    }, [])
    const navigate=useNavigate()
    const goBack = () => {
		navigate({
            pathname: '/',
          });
	}
    return (
      <>
        <section>
          <div className="container-fluid2">
            {/* <Link to={'/'} search={`?role=${userType.role}&userId=${userType?.userId}`}> */}
            <button
              type="button"
              className="btn-close trade_table_close2"
              onClick={goBack}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              ×
            </button>
            {/* </Link> */}
            <div className="row">
              {/* <SideBar /> */}
              {/* <!--------ul-----clouse----> */}
              <div className="col-lg-12 ">
                {/* <Header/> */}
                <TradeTable
                  portfololioList={portfololioList}
                  setRunAgainDashBoard={setRunAgainDashBoard}
                  runAgainDashBoard={runAgainDashBoard}
                  userType={userType}
                  setCalculatedData={setCalculatedData}
                  setOpenTradeRun={setOpenTradeRun}
                  setTradeCode={setTradeCode}
                  setCalculatedTradeData={setCalculatedTradeData}
                />
              </div>
            </div>
          </div>
        </section>
        {/* <Footer /> */}

        {/* models */}
        <TradeModel tradeCode={tradeCode} />
        <OpenTrade
          providerList={providerList}
          setRunAgainDashBoard={setRunAgainDashBoard}
          runAgainDashBoard={runAgainDashBoard}
          setCalculatedData={setCalculatedData}
          userType={userType}
          calculatedData={calculatedData}
          openTradeRun={openTradeRun}
          portfololioList={portfololioList}
        />
        <TradeCalculater
          providerList={providerList}
          portfololioList={portfololioList}
          setCalculatedData={setCalculatedData}
          setOpenTradeRun={setOpenTradeRun}
          openTradeRun={openTradeRun}
          userType={userType}
          calculatedTradeData={calculatedTradeData}
          setCalculatedTradeData={setCalculatedTradeData}
        />
      </>
    );
}

export default Trade