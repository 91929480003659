import React, { useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import * as Yup from "yup";
import { getSearchStockDetails } from "../../../services/HomeService";
import {
  GetExchangeRate,
  GetExchangeRateTrade,
  GetRapidExchangeRate,
  GetRapidExchangeRateTrade,
} from "../../../services/TradeService";
import { modifyOpenTrade } from "../../../services/TradeService";
import { toast } from "react-toastify";
import { GetCurrentBalabce } from "../../../services/ProviderServices";
import { GetOpenTradeListByUserId } from "../../../services/TradeService";
const validationSchemaBuy = Yup.object().shape({
  quantity: Yup.string().required("Quantity is required"),
  amount: Yup.string().required("Amount is required"),
  // providerId: Yup.string().required('Provider is required'),
});
const BuySellUpdateTrade = ({
  portfololioList,
  handleGetOpenTradelist,
  onSave,
  allCountryList,
  buySellTradeData,
  setBuySellTradeData,
  portFolipID,
  userType,
}) => {
  const [buySellInput, setBuySellInput] = useState({
    quantity: "",
    amount: "",
    lotValue: "",
    Lotquantity: "",
  });
  const [stockCurrentData, setStockCurrentData] = useState({
    price: "0",
    profit: "0",
    profitPer: "0",
  });
  const [exchangeRate, setExchangeRate] = useState();
  const [brokerData, setBrokerData] = useState("");
  const { price, profit, profitPer } = stockCurrentData;
  const [popUpShow, setPopUpShow] = useState(false);
  const [stockexrate, setStockExRate] = useState("");
  const twoDecimal = (value) => {
    // Check if the value is undefined or null
    if (value === undefined || value === null) {
      return ""; // Or any default value that you want to return in case of undefined/null
    }

    var roundedNumber = String(value); // Convert value to string
    var decimalIndex = roundedNumber.indexOf(".");

    if (decimalIndex !== -1) {
      var decimalPlaces = roundedNumber.length - decimalIndex - 1;
      if (decimalPlaces > 2) {
        roundedNumber = roundedNumber.slice(0, decimalIndex + 3);
      }
    }

    return roundedNumber;
  };

  const toastQueue = [];

  const showToast = (message) => {
    toastQueue.push(message);
    if (!popUpShow) {
      displayNextToast();
    }
  };

  const displayNextToast = () => {
    if (toastQueue.length > 0) {
      const message = toastQueue.shift();
      toast.error(message, {
        autoClose: 3000, // Set the duration as needed
        // onClose: () => {
        //     setPopUpShow(false);
        //     displayNextToast(); // Display the next toast message
        // },
      });
      setPopUpShow(true);
      setTimeout(() => {
        setPopUpShow(false);
        // displayNextToast();
      }, 3500);
    }
  };
  const getcurrentBalance = () => {
    // debugger;
    let cData = {
      portfolioId: portFolipID,
      userId: userType?.userId,
    };
    if (cData.userId !== undefined && cData.portfolioId !== undefined) {
      try {
        // debugger;
        GetCurrentBalabce(cData).then((res) => {
          if (res?.status === 200) {
            console.log("res?.data", res?.data);
            setBrokerData(res.data);
          }
        });
      } catch (error) {}
    }
  };
  const [askprice, setAskPrice] = useState("");
  const [bidprice, setBidPrice] = useState("");
  const handleRemoveBuySelldata = () => {
    setBuySellTradeData({});
    setBuySellInput({ quantity: "", amount: "", Lotquantity: "", lotValue: "" });
  };
  const getExchangeRate = () => {
    // debugger
    if (buySellTradeData?.stockCode !== undefined) {
      let stockPortCountry = portfololioList.filter(
        (val) => val.portfolioId == buySellTradeData.portfolioId
      );
      let stockCurrency = buySellTradeData?.stockCode.split("/") || "";
      try {
        // GetExchangeRateTrade(buySellTradeData.stockCode).then((respons) => {
        GetRapidExchangeRateTrade(buySellTradeData.stockCode).then(
          (respons) => {
            const CurrentBidPrice =
              respons?.data?.rates?.[buySellTradeData.stockCode.split("/")[1]];
            const CurrentAskPrice =
              respons?.data?.rates?.[buySellTradeData.stockCode.split("/")[1]];
            setAskPrice(CurrentAskPrice);
            setBidPrice(CurrentBidPrice);
            // const exPrice = res?.data["Realtime Currency Exchange Rate"]?.["5. Exchange Rate"];
            if (
              CurrentAskPrice !== undefined &&
              CurrentBidPrice !== undefined
            ) {
              setBuySellInput({
                ...buySellInput,
                entryPrice:
                  buySellTradeData.short == "buy"
                    ? CurrentAskPrice
                    : CurrentBidPrice,
              });
              setStockCurrentData({
                ...stockCurrentData,
                //   profit: (data.close - data.open).toString(), profitPer: twoDecimal(((data.close - data.open) * 100 / data.open).toString())
              });
              // setAskPrice(ask);
              // setBidPrice(bid);
            } else {
              setStockCurrentData({
                profit: twoDecimal(
                  (
                    buySellInput.entryPrice - buySellTradeData.ask_price
                  ).toString()
                ),
              });
              // setAskPrice(null)
              // setBidPrice(null)
            }

            let exchangeParam = {
              CurrencyFrom: stockCurrency[1],
              CurrencyTo: stockPortCountry[0]?.currency,
            };
            // console.log(exchangeParam, "eschangeParam")
            try {
              // GetExchangeRate(exchangeParam).then((res) => {
              GetRapidExchangeRate(exchangeParam).then((res) => {
                if (res?.status === 200) {
                  const exPrice = Number(
                    res?.data?.rates?.[exchangeParam?.CurrencyTo]
                  );
                  let ask = Number(
                    res?.data?.rates?.[exchangeParam?.CurrencyTo]
                  );
                  let bid = Number(
                    res?.data?.rates?.[exchangeParam?.CurrencyTo]
                  );

                  setExchangeRate(exPrice);
                  // debugger
                }
                // getAskPrice(afterSlash ,beforeSlash)
              });
            } catch (error) {}
          }
        );
      } catch (error) {}
    }
    //     //  let selectedStockCountry = allCountryList.filter((val) => val.key.toUpperCase() == buySellTradeData.country.toUpperCase())
    //     // console.log(selectedStockCountry)
    //     let stockPortCountry = portfololioList.filter((val) => val.portfolioId == buySellTradeData.portfolioId)
    //     // let stockCurrency = tradeListData[i].stockCode.split('/')
    //     console.log(stockPortCountry, "stockPortCountry")
    //     let selectedStockCountry = "AUD"
    //     try {
    //         GetExchangeRate(selectedStockCountry, buySellTradeData?.portfolio?.currency).then((res) => {
    //             if (res.status == 200) {
    //                 let exchange = res?.data['Realtime Currency Exchange Rate']?.['5. Exchange Rate'] || 1

    //                 setExchangeRate(exchange)
    //             }
    //         })
    //     } catch (error) {

    //     }
    // }

    // let stockCurrency = buySellTradeData
  };
  // useEffect(() => {
  //     getExchangeRate()
  // }, [])

  // const getCurrentPrice = () => {
  //     // if (buySellTradeData?.stockCode) {
  //     //     try {
  //     //         getSearchStockDetails(buySellTradeData?.stockCode).then((res) => {
  //     //             if (res.status == 200) {
  //     //                 let data = res.data
  //     //                 // let aus = buySellTradeData?.stockCode.includes(".AX")
  //     //                 let aus = 'AUD'
  //     //                 if (aus) {
  //     //                     // debugger
  //     //                     let data = res.data
  //     //                     console.log(data, "data")
  //     //                     setStockCurrentData({ price: (data.close).toString(), profit: twoDecimal((data.close - data.open).toString()), profitPer: twoDecimal(((data.close - data.open) * 100 / data.open).toString()) })
  //     //                 } else {
  //     //                     const close = res.data["Global Quote"]["05. price"];
  //     //                     const open = res.data["Global Quote"]["02. open"];
  //     //                     console.log(open, "open ")
  //     //                     setStockCurrentData({ price: (close).toString(), profit: twoDecimal((close - open).toString()), profitPer: twoDecimal(((close - open) * 100 / open).toString()) })
  //     //                 }
  //     //             }
  //     //             console.log(res)

  //     //         })
  //     //     } catch (error) {

  //     //     }
  //     // }
  // }
  useEffect(() => {
    // debugger;
    getcurrentBalance();
    getExchangeRate();
    //getCurrentPrice()
  }, [buySellTradeData?.stockCode, portFolipID, userType]);

  const handleBlurInputPort = () => {};

  const handleChangeBuySelloInput = (e) => {
    // debugger
    if (e.target.name == "quantity") {
      // debugger
      let vs = price * Math.round(e.target.value);
      if (
        Number(e.target.value) >= 0 &&
        e.target.value != "" &&
        e.target.value.length <= 10
      ) {
        let n = e.target.value.split(".");
        let value = twoDecimal(e.target.value);
        if (n.length == 2) {
          if (n[0].length <= 10) {
            setBuySellInput({
              ...buySellInput,
              [e.target.name]: value,
              amount: twoDecimal((value * (price * exchangeRate)).toString()),
            });
          }
        } else if (brokerData?.currentBalance < vs) {
          showToast("Does not have sufficient balance!");
        } else if (e.target.value.length <= 10) {
          setBuySellInput({
            ...buySellInput,
            [e.target.name]: value,
            amount: twoDecimal((value * buySellInput.entryPrice).toString()),
          });
        }
      } else if (e.target.value == "") {
        setBuySellInput({ ...buySellInput, [e.target.name]: "", amount: "" });
      }
    } else {
      if (
        Number(e.target.value) >= 0 &&
        e.target.value != "" &&
        e.target.value.length <= 10
      ) {
        let n = e.target.value.split(".");
        let value = twoDecimal(e.target.value);
        if (n.length == 2) {
          if (n[0].length <= 10) {
            setBuySellInput({
              ...buySellInput,
              [e.target.name]: value,
              quantity: twoDecimal((value / (price * exchangeRate)).toString()),
            });
          }
        } else if (e.target.value.length <= 10) {
          setBuySellInput({
            ...buySellInput,
            [e.target.name]: value,
            quantity: twoDecimal((value / (price * exchangeRate)).toString()),
          });
        }
      } else if (e.target.value == "") {
        setBuySellInput({ ...buySellInput, [e.target.name]: "", quantity: "" });
      }
    }

    if (e.target.name == "lotValue") {
      if (Number(e.target.value) >= 0 && e.target.value != "") {
        const newLotValue = e.target.value;
        const lotValueFloat = parseFloat(newLotValue);
        let calculatedAmount = lotValueFloat * 100000;

        let getask = askprice * calculatedAmount;
        let getbid = bidprice * calculatedAmount;

        // console.log(nm, "nm")
        // console.log(entryPrice, calculatedAmount, exchangerate)
        let n = e.target.value.split(".");
        let lqtValue = twoDecimal(calculatedAmount.toString());
        let ltValue = newLotValue.toString();
        let buyamtValRounded = twoDecimal(getask.toString());
        let sellamtValRounded = twoDecimal(getbid.toString());
        // console.log(amtValRounded, "amtValRounded")

        if (n.length == 2) {
          if (n[0].length <= 10) {
            setBuySellInput({
              ...buySellInput,
              lotValue: ltValue,
              Lotquantity: lqtValue,
              buyamountvalue: buyamtValRounded,
              sellamountvalue: sellamtValRounded,
            });
          }
        } else if (n.length == 1 && e.target.value.length <= 10) {
          setBuySellInput({
            ...buySellInput,
            lotValue: ltValue,
            Lotquantity: lqtValue,
            buyamountvalue: buyamtValRounded,
            sellamountvalue: sellamtValRounded,
          });
        }
      } else if (e.target.value == "") {
        setBuySellInput({
          ...buySellInput,
          lotValue: "",
          Lotquantity: "",
          buyamountvalue: "",
          sellamountvalue: "",
        });
      }
    } else if (e.target.name == "AmountLot") {
      // debugger
      if (Number(e.target.value >= 0) && e.target.value != "") {
        let lotValueFloat = 0;
        let nm = "";
        if (Number(e.target.value) >= 0) {
          lotValueFloat = Number(e.target.value) / 100000;
        }
        let n = e.target.value.split(".");
        let nmValue = twoDecimal(e.target.value.toString());
        let ltValue = lotValueFloat.toString();

        let amtValRounded = twoDecimal(nm.toString());
        if (n.length == 2) {
          if (n[0].length <= 10) {
            setBuySellInput({
              ...buySellInput,
              lotValue: ltValue,
              Lotquantity: nmValue,
            });
          } else if (n.length == 1 && e.target.value.length <= 10) {
            setBuySellInput({
              ...buySellInput,
              lotValue: ltValue,
              Lotquantity: nmValue,
            });
          }
        } else if (e.target.value == "") {
          setBuySellInput({
            ...buySellInput,
            lotValue: "",
            Lotquantity: "",
          });
        }
      }
    }
  };
  // console.log(buySellTradeData ,"buySellTradeData")
  const handleSubmitBuySell = async (type) => {
    // debugger;
    let stockPriceValue =
      type === "buy"
        ? buySellInput.buyamountvalue
        : buySellInput.sellamountvalue;
    let getcalculatedask = askprice * buySellInput?.Lotquantity;
    let value = {
      TradeId: buySellTradeData?.transactionNo,
      // Qty: buySellInput?.quantity,
      Qty: buySellInput.Lotquantity,
      // StockPrice: buySellInput?.amount,
      StockPrice: stockPriceValue,
      Short: type,
    };
    try {
      if (type === "buy") {
        // debugger;
        // Check if the current balance is sufficient for buying
        if (brokerData?.currentBalance < getcalculatedask) {
          showToast("Does not have sufficient balance!");
          return;
        }
      } else if (type === "sell") {
        // debugger;
        // Check if the quantity is not greater than available quantity for selling
        if (buySellInput?.Lotquantity > buySellTradeData?.quantity) {
          showToast("Does not have sufficient Forex!");
          return;
        }
      }
      const res = await modifyOpenTrade(value);
      if (res?.status === 200) {
        onSave();
        handleGetOpenTradelist("openTrade");
        document.getElementById("closeBuySell").click();
      }
    } catch (error) {}
  };

  return (
    <div
      className="modal fade show backdrop-1"
      data-bs-backdrop="static"
      id="BuySellUpdateTrade"
      tabindex="-1"
      aria-labelledby="exampleModalLgLabel"
      style={{ display: "none" }}
      aria-modal="true"
      role="dialog"
    >
      <div className="modal-dialog modal-lg  modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header p-0 border-bottom-0">
            {/* <h5 className="modal-title h4 buySell_main" id="exampleModalLgLabel">{buySellTradeData?.stockCode} <span>{twoDecimal(buySellInput.entryPrice)}</span> <span className={profit >= 0 ? 'green' : 'red'} >{profit}</span> (<span className={profit >= 0 ? 'green' : 'red'}>{profitPer}%</span>) </h5> */}
            <h5
              className="modal-title h4 buySell_main"
              id="exampleModalLgLabel"
            >
              {buySellTradeData?.stockCode}{" "}
              <span className="buysellspan">Ask </span>
              <span>
                {!isNaN(parseFloat(askprice)) && isFinite(askprice)
                  ? parseFloat(askprice).toFixed(5)
                  : "0"}
              </span>{" "}
              <span className="buysellspan">Bid</span>{" "}
              <span>
                {!isNaN(parseFloat(bidprice)) && isFinite(bidprice)
                  ? parseFloat(bidprice).toFixed(5)
                  : "0"}
              </span>
            </h5>
            <button
              type="button"
              className="btn-close btn-close-provider"
              id="closeBuySell"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleRemoveBuySelldata}
            >
              ×
            </button>
          </div>
          <div className="col-lg-12">
            <Formik
              initialValues={buySellInput}
              enableReinitialize={true}
              validationSchema={validationSchemaBuy}
              onSubmit={(value) => {
                // handleSubmitBuySell()
              }}
            >
              {({ errors, touched }) => (
                <Form className="row p-3">
                  {/* <div class="col-sm-12"> */}
                  <div class="col-6">
                    <label>
                      Total Forex<span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      value={buySellTradeData?.quantity || 0}
                      disabled
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="portfolioName"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                    />
                    {errors.portfolioName && touched.portfolioName ? (
                      <div className="color-msg-err">
                        {errors.portfolioName}
                      </div>
                    ) : null}
                  </div>
                  <div class="col-6">
                    <label>
                      Total Amount <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      value={twoDecimal(
                        (
                          (buySellTradeData?.valueInShares || 0) +
                          (buySellTradeData?.profitLoss || 0)
                        ).toString()
                      )}
                      disabled
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="portfolioName"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                    />
                    {errors.portfolioName && touched.portfolioName ? (
                      <div className="color-msg-err">
                        {errors.portfolioName}
                      </div>
                    ) : null}
                  </div>
                  {/* <div class="col-6 mt-2">
                                        <label>Stocks <span style={{ "color": "#f00" }}>*</span></label>
                                        <Field type="text" class="form-control" onBlur={handleBlurInputPort} name='quantity' onChange={handleChangeBuySelloInput} id="autoSizingInput" placeholder="" />
                                        {errors.quantity && touched.quantity ? (
                                            <div className='color-msg-err'>{errors.quantity}</div>
                                        ) : null}
                                    </div>

                                    
                                    <div class="col-6 mt-2">
                                        <label>Amount <span style={{ "color": "#f00" }}>*</span></label>
                                        <Field type="text" class="form-control" onBlur={handleBlurInputPort} name='amount' onChange={handleChangeBuySelloInput} id="autoSizingInput" placeholder="" />
                                        {errors.amount && touched.amount ? (
                                            <div className='color-msg-err'>{errors.amount}</div>
                                        ) : null}
                                    </div> */}
                  <div class="col-6 mt-2">
                    <label>
                      Lot <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="lotValue"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                      value={buySellInput.lotValue}
                    />
                    {/* {errors.quantity && touched.quantity ? (
                                            <div className='color-msg-err'>{errors.quantity}</div>
                                        ) : null} */}
                  </div>

                  <div class="col-6 mt-2">
                    <label>
                      Amount Lot <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="AmountLot"
                      id="autoSizingInput"
                      placeholder=""
                      value={buySellInput.Lotquantity}
                    />
                    {/* {errors.quantity && touched.quantity ? (
                                            <div className='color-msg-err'>{errors.quantity}</div>
                                        ) : null} */}
                  </div>

                  <div class="col-6 mt-2">
                    <label>
                      Buy Amount value <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="amount"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                      value={buySellInput.buyamountvalue}
                    />
                    {/* {errors.quantity && touched.quantity ? (
                                            <div className='color-msg-err'>{errors.quantity}</div>
                                        ) : null} */}
                  </div>

                  <div class="col-6 mt-2">
                    <label>
                      {" "}
                      Sell Amount value <span style={{ color: "#f00" }}>*</span>
                    </label>
                    <Field
                      type="text"
                      class="form-control"
                      onBlur={handleBlurInputPort}
                      name="amount"
                      onChange={handleChangeBuySelloInput}
                      id="autoSizingInput"
                      placeholder=""
                      value={buySellInput.sellamountvalue}
                    />
                    {/* {errors.quantity && touched.quantity ? (
                                            <div className='color-msg-err'>{errors.quantity}</div>
                                        ) : null} */}
                  </div>

                  <div className="col-md-4 d-flex">
                    {/* <button class="btn d-block ms-md-3 ms-2 mt-3 w-100" type='submit' >{showEdit === true ? 'Edit Porfolio' : <>Add Porfolio <span> <i class="bi bi-plus-lg"></i> </span></>}   <span>  </span></button> */}

                    <button
                      class="btn buyButton mt-3 w-100"
                      style={{ padding: "2px 37px" }}
                      onClick={() => handleSubmitBuySell("buy")}
                      type="submit"
                    >
                      Buy
                    </button>
                    <button
                      class="btn ms-md-2 ms-2  mt-3 w-100 sellButton"
                      style={{ padding: "2px 37px" }}
                      onClick={() => handleSubmitBuySell("sell")}
                      id="Cancle-portFolio"
                      type="submit"
                    >
                      Sell
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          {/* <div className="modal-body blackout-bx">

                        <div class="table-responsive">
                            <table class="table table-input1 table-bordered mt-3 text-center">
                                <thead>
                                    <tr>
                                        <th scope="col" className='color-grey'>Date</th>
                                        <th scope="col" className='color-grey'>Price</th>
                                        <th scope="col" className='color-grey'>Quantity</th>
                                        <th scope="col" className='color-grey'>Amount</th>
                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>
                    </div> */}
        </div>
      </div>
    </div>
  );
};

export default BuySellUpdateTrade;
